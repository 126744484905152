import React from "react";
import CarouselCustom from "../../components/CarouselCustom";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import NotLoggedIn from "./NotLoggedIn";
import LoggedIn from "./LoggedIn";
import PurchaseExpired from "./PurchaseExpired";
import SubmissionExpired from "./SubmissionExpired";
import PartialExpired from "./PartialExpired";
import Expired from "./Expired";
const Home = (props) => {
	const { t, i18n } = useTranslation();
	const user = useSelector((state) => state.user);
	const config = useSelector((state) => state.app.config);
	React.useEffect(() => {
		document.title = t("COMMON.TITLE");
	}, [i18n.language]);
	return (
		<>
			<CarouselCustom />
			{(() => {
				if (config.campaignState === "purchaseExpired" || config.submissionEnded === "purchaseExpired") {
					if (user.accessToken) {
						return <LoggedIn />;
					} else {
						return <PurchaseExpired />;
					}
				} else if (config.campaignState === "submissionExpired" || config.submissionEnded === "submissionExpired") {
					if (user.accessToken) {
						return <LoggedIn />;
					} else {
						return <SubmissionExpired />;
					}
				} else if (config.campaignState === "partialExpired") {
					return <PartialExpired />;
				} else if (config.campaignState === "expired") {
					return <Expired />;
				} else {
					if (!user?.accessToken) {
						return <NotLoggedIn />;
					}
					if (user.accessToken) {
						return <LoggedIn />;
					}
				}
			})()}
		</>
	);
};

export default IsLoadingHOC(Home, "");
