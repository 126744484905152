import React from "react";
import { useTranslation } from "react-i18next";

const FooterTerms = (props) => {
	const { t, i18n } = useTranslation();
	React.useEffect(() => {
		document.title = t("COMMON.TITLE");
	}, [i18n.language]);
	return (
		<>
				<div className="footer-terms-notes px-5 py-5">
				{/* <small className="ff-StdMedium py-4">{t("FOOTER_TERMS_NOTES")}</small> */}
                <small className="p-small" dangerouslySetInnerHTML={{ __html: `${t("FOOTER_TERMS_NOTES")}` }}></small>
				</div>
		</>
	);
};

export default FooterTerms;
