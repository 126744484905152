import React, { useState } from "react";
import CarouselCustom from "../../components/CarouselCustom";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { commonImgData_en_exp, commonImgData_fr_exp } from "../../data";
import { Container, Modal } from "react-bootstrap";
import Login from "../Login";
import VideoModal from "../../components/VideoModal";
const Expired = (props) => {
	const { t, i18n } = useTranslation();
	const [showVideoModal, setShowVideoModal] = useState(false);
	const selected_lang = i18n.language;
	const config = useSelector((state) => state.app.config);
	const handleToggleModal = () => {
		setShowVideoModal(!showVideoModal);
	};
	return (
		<Container fluid className="pt-2">
			<div className="row justify-content-center my-5 expired">
				<div className="py-5 styleColor">
					<h1 className="pt-3 styleColor" dangerouslySetInnerHTML={{ __html: t("COMMON.EXPIRED_HEADING") }}></h1>
					<p className="text-center font-30px ff-StdHeavy">{t("COMMON.EXPIRED_SUB")}</p>
				</div>
			</div>
		</Container>
	);
};

export default IsLoadingHOC(Expired, "");
